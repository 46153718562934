import React, { useState } from "react";
import { Link } from "gatsby";
import "./menu.css";

export default function Menu(props) {
  //grab width using util

  const [menuIsOpen, setMenuIsOpen] = useState(false);

  return (
    <>
      <div className="menuMobileWrapper">
        <div className={`menuMobile ${menuIsOpen ? "menuOpen" : ""}`}>
          <Link className="menuLinkMobile" to="/">
            Research
          </Link>
          <Link className="menuLinkMobile" to="/upcoming">
            Upcoming
          </Link>
          <Link className="menuLinkMobile" to="/publication">
            Publication
          </Link>
          <Link className="menuLinkMobile" to="/about">
            About
          </Link>
          <button
            style={{ display: "inline-block" }}
            className="menuClose"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <svg
              width="13"
              height="13"
              viewBox="0 0 13 13"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line
                x1="0.353553"
                y1="0.646447"
                x2="12.3536"
                y2="12.6464"
                stroke="black"
              />
              <line
                x1="12.3536"
                y1="0.353553"
                x2="0.353553"
                y2="12.3536"
                stroke="black"
              />
            </svg>
          </button>
        </div>
      </div>
      <nav
        className={
          props.type === "Essay" || props.type === "Exhibition"
            ? (menuIsOpen ? "menuOpen " : "menuShut ") + `whiteText`
            : menuIsOpen
            ? "menuOpen"
            : "menuShut"
        }
      >
        <div className="menuLeft">
          <button
            style={{ display: "inline-block" }}
            className="menuButton"
            onClick={() => setMenuIsOpen(!menuIsOpen)}
          >
            <svg
              width="12"
              height="11"
              viewBox="0 0 12 11"
              fill="none"
              xmlns="http://www.w3.org/2000/svg"
            >
              <line y1="0.5" x2="12" y2="0.5" stroke="black" />
              <line y1="5.5" x2="12" y2="5.5" stroke="black" />
              <line y1="10.5" x2="12" y2="10.5" stroke="black" />
            </svg>
          </button>
          <Link activeClassName="menuActive" className="menuLink" to="/">
            Research
          </Link>
          <Link activeClassName="menuActive" className="menuLink" to="/recent">
            Recent
          </Link>
          <Link
            activeClassName="menuActive"
            className="menuLink"
            to="/publication"
          >
            Publication
          </Link>
          <Link activeClassName="menuActive" className="menuLink" to="/about">
            About
          </Link>
        </div>
        <Link to="/" className="menuTitle">
          System of Systems
        </Link>
      </nav>
    </>
  );
}
