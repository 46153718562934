import React from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image";

export default function FooterLogos(props) {
  const data = useStaticQuery(graphql`
    {
      contentfulFooterLogosAndLinks {
        logo {
          gatsbyImageData(height: 30, placeholder: BLURRED)
        }
      }
    }
  `);

  let logos = [];

  if (data.contentfulFooterLogosAndLinks.logo) {
    logos = data.contentfulFooterLogosAndLinks.logo.map((logo) => (
      <GatsbyImage
        className="footerLogo"
        placeholder="blurred"
        height="30px"
        image={getImage(logo.gatsbyImageData)}
      />
    ));
  }

  return (logos = [] ? (
    <div className="footerSupportWrapper">
      <p>Supported by</p>
      <div className="footerSupport">{logos}</div>
    </div>
  ) : null);
}
