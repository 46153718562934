import React, { useState } from "react";
import { Link } from "gatsby";
import { useStaticQuery, graphql } from "gatsby";
import Marquee from "react-fast-marquee";

import { BLOCKS, MARKS } from "@contentful/rich-text-types";
import { renderRichText } from "gatsby-source-contentful/rich-text";

const Bold = ({ children }) => <span className="bold">{children}</span>;
const Text = ({ children }) => <p>{children}</p>;

const options = {
  renderMark: {
    [MARKS.BOLD]: (text) => <Bold>{text}</Bold>,
  },
  renderNode: {
    [BLOCKS.PARAGRAPH]: (node, children) => <Text>{children}</Text>,
  },
  renderText: (text) => {
    let textToRender = text
      .split("\n")
      .reduce((children, textSegment, index) => {
        return [...children, index > 0 && <br key={index} />, textSegment];
      }, []);

    return textToRender;
  },
};

export default function Scroller(props) {
  const data = useStaticQuery(graphql`
    {
      contentfulScrollingAnnouncement {
        id
        announcement {
          raw
        }
      }
    }
  `);

  const { announcement } = data.contentfulScrollingAnnouncement;

  return (
    <div
      className={
        props.type === "Essay" || props.type === "Exhibition"
          ? `scroller whiteText`
          : `scroller`
      }
    >
      <Marquee pauseOnHover={true} gradient={false}>
        {announcement && renderRichText(announcement, options)}
        {announcement && renderRichText(announcement, options)}
        {announcement && renderRichText(announcement, options)}
        {announcement && renderRichText(announcement, options)}
      </Marquee>
    </div>
  );
}
