/**
 * Layout component that queries for data
 * with Gatsby's useStaticQuery component
 *
 * See: https://www.gatsbyjs.com/docs/use-static-query/
 */

import * as React from "react";
import PropTypes from "prop-types";
import Menu from "../components/Menu";
import Footer from "../components/Footer";
import Scroller from "../components/Scroller";

import { useState, useEffect } from "react";

import "./layout.css";

const Layout = ({ children, type }) => {
  return (
    <>
      {type && (
        <div className={`articleBackground ${type} Background`}>
          <div
            className={`Guides WhiteGuides  ${type ? `` : `noDisplay`}`}
            aria-hidden="true"
          >
            <div className="Guides_container">
              <div className="Guides_guide"></div>
              <div className="Guides_guide"></div>
              <div className="Guides_guide Guides_desktop"></div>
              <div className="Guides_guide Guides_desktop"></div>
              <div className="Guides_guide "></div>
            </div>
          </div>
        </div>
      )}
      <Scroller type={type} />
      <Menu type={type} />
      <main>
        {children}

        <div className={`Guides ${type ? `noDisplay` : ""}`} aria-hidden="true">
          <div className="Guides_container">
            <div className="Guides_guide"></div>
            <div className="Guides_guide"></div>
            <div className="Guides_guide Guides_desktop"></div>
            <div className="Guides_guide Guides_desktop"></div>
            <div className="Guides_guide "></div>
          </div>
        </div>
      </main>
      <Footer />
    </>
  );
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
};

export default Layout;
