import React, { useState, useEffect } from "react";
import { useStaticQuery, Link, graphql } from "gatsby";
import soslogo from "../images/sos-logo.svg";
import addToMailchimp from "gatsby-plugin-mailchimp";
import FooterLogos from "./FooterLogos";

export default function Footer(props) {
  const [userEmail, setUserEmail] = useState("");
  const [didEmailSubmit, setDidEmailSubmit] = useState(null);
  const [emailMessage, setEmailMessage] = useState(
    "Subscribe to our monthly newsletter"
  );

  let links = [];

  const data = useStaticQuery(graphql`
    {
      contentfulFooterLogosAndLinks {
        link {
          linkUrl
          linkTitle
        }
        logo {
          gatsbyImageData(height: 60, placeholder: BLURRED)
        }
      }
    }
  `);

  // Since `addToMailchimp` returns a promise, you
  // can handle the response in two different ways:

  // Note that you need to send an email & optionally, listFields
  // these values can be pulled from React state, form fields,
  // or wherever.  (Personally, I recommend storing in state).

  // 1. via `.then`
  let _handleSubmit = (e) => {
    e.preventDefault();

    addToMailchimp(userEmail) // listFields are optional if you are only capturing the email address.
      .then((data) => {
        // I recommend setting data to React state
        // but you can do whatever you want (including ignoring this `then()` altogether)
        setDidEmailSubmit(data);
      })
      .catch(() => {
        // unnecessary because Mailchimp only ever
        // returns a 200 status code
        // see below for how to handle errors
      });
  };

  useEffect(() => {
    console.log(didEmailSubmit);
    if (didEmailSubmit) {
      if (didEmailSubmit.result == "success") {
        setEmailMessage("Thanks! You're subscribed");
      } else if (didEmailSubmit.result == "error") {
        if (didEmailSubmit.msg.startsWith("The email you entered")) {
          setEmailMessage("Invalid email. Please check and try again");
        } else if (didEmailSubmit.msg.startsWith(userEmail)) {
          setEmailMessage("You're already subscribed!");
        } else if (
          didEmailSubmit.msg.startsWith("Too many subscribe attempts")
        ) {
          setEmailMessage("Too many attempts. Try again later");
        } else setEmailMessage("Error. Please check your email and try again.");
      }
    }
  }, [didEmailSubmit]); // Only re-run the effect if count changes

  const handleKeyDown = (e) => {
    if (e.key === "Enter") {
      _handleSubmit(e, userEmail);
    } else setUserEmail(e.target.value);
  };

  if (data.contentfulFooterLogosAndLinks.link) {
    links = data.contentfulFooterLogosAndLinks.link.map((link) => (
      <span className="footerLink">
        <a href={link.linkUrl} target="_blank" rel="noopener noreferrer">
          {link.linkTitle}
        </a>
        <span>•</span>
      </span>
    ));
  }

  return (
    <div className="footerWrapper">
      <footer>
        <Link to="/" className="footerLogoWrapper">
          <img src={soslogo} />
        </Link>
        <a
          onClick={() => {
            window.scrollTo({
              top: 0,
              behavior: "smooth",
            });
          }}
          className="footerUp"
        >
          <svg
            width="12"
            height="18"
            viewBox="0 0 12 18"
            fill="none"
            xmlns="http://www.w3.org/2000/svg"
          >
            <path
              fill-rule="evenodd"
              clip-rule="evenodd"
              d="M0.648437 5.64756L6.00199 0.294008L11.3555 5.64756L10.6484 6.35467L6.50199 2.20822L6.50199 17.4297L5.50199 17.4297L5.50199 2.20822L1.35554 6.35467L0.648437 5.64756Z"
              fill="black"
            />
          </svg>
        </a>
        <div className="footerNewsletter">
          <form onSubmit={(e) => _handleSubmit(e, userEmail)} id="newsletter">
            <label htmlFor="email">{emailMessage}</label>
            <div id="newsletterWrapper">
              <input
                placeholder="email@example.com"
                value={userEmail}
                onChange={(e) => handleKeyDown(e)}
                type="email"
                id="email"
                name="email"
              />
              <button
                type="submit"
                form="newsletter"
                value="Submit"
                id="emailSubmit"
                onClick={(e) => _handleSubmit(e, userEmail)}
              >
                Submit
              </button>
            </div>
          </form>
        </div>
        <p className="footerEmail">info@systemofsystems.eu</p>
        {data.contentfulFooterLogosAndLinks.link && (
          <div className="footerLinks">{links}</div>
        )}

        <FooterLogos />

        <div className="Guides" aria-hidden="true">
          <div className="Guides_container">
            <div className="Guides_guide"></div>
            <div className="Guides_guide"></div>
            <div className="Guides_guide Guides_desktop"></div>
            <div className="Guides_guide Guides_desktop"></div>
            <div className="Guides_guide "></div>
          </div>
        </div>
      </footer>
    </div>
  );
}
